<template>
	<div id="name-and-country">
		<div class="input-container">
			<input type="text" :placeholder="languageStrings.firstName" v-model="firstName" />
			<input type="text" :placeholder="languageStrings.lastName" v-model="lastName" />
			<div id="country-dropdown-container">
				<CountryDropdownMenu
					v-if="countryList?.length > 0"
					:systemSettings="systemSettings"
					:playerState="playerState"
					:countryList="countryList"
					:languageStrings="languageStrings"
					:countryDropdownType="`FirstAndLastName`"
				/>
			</div>
			<button class="btn" :title="languageStrings.continueText" @click="submitToParent()">{{ languageStrings.continueText }}</button>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import CountryDropdownMenu from "@/components/CountryDropdownMenu";

export default {
	name: "FirstAndLastName",
	props: {
		systemSettings: Object,
		playerState: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		CountryDropdownMenu,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			firstName: "",
			lastName: "",
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "FirstAndLastName",
			},
		};
	},
	watch: {},
	created() {
		this.eventBus.on("countrySelected", (payload) => {
			this.newAccountProperty.propertyName = "addressCountry";
			this.newAccountProperty.propertyValue = payload.name;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);
		});
		onBeforeUnmount(() => {
			this.eventBus.off("countrySelected");
		});
	},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.firstName) {
				this.status.ok = false;
				this.status.message = this.languageStrings.firstNameIsRequired;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (!this.lastName) {
				this.status.ok = false;
				this.status.message = this.languageStrings.lastNameIsRequired;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			// There's probably a better way to pass data to the parent component
			// Two separate events were just the quickest way since we release in two days 07/19/24
			this.newAccountProperty.propertyName = "firstName";
			this.newAccountProperty.propertyValue = this.firstName;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.newAccountProperty.propertyName = "lastName";
			this.newAccountProperty.propertyValue = this.lastName;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
}
</style>
