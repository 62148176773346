<template>
	<div class="input-container">
		<h2>{{ languageStrings.enterPassword }}</h2>
		<small style="text-align: center"
			>{{ languageStrings.passwordRequirements1 }} {{ serverRules?.passwordRules?.minimumLength }}
			{{ languageStrings.passwordRequirements2 }}
			<span v-if="serverRules?.passwordRules?.minimumNumberOfCharacterClasses > 0">
				{{ languageStrings.passwordRequirements3 }} {{ serverRules?.passwordRules?.minimumNumberOfCharacterClasses }}
				{{ languageStrings.passwordRequirements4 }}
			</span>
		</small>
		<input type="password" :placeholder="languageStrings.password" v-model="password" autocomplete="off" />
		<input type="password" :placeholder="languageStrings.confirmPassword" v-model="confirmPassword" autocomplete="off" />
		<button
			:disabled="(!password.length > 0 && !confirmPassword.length > 0) || password !== confirmPassword"
			class="btn"
			:title="
				(!password.length > 0 && !confirmPassword.length > 0) || password !== confirmPassword
					? languageStrings.passwordsMustMatch
					: languageStrings.continueText
			"
			@click="submitToParent()"
		>
			{{ languageStrings.registerText }}
		</button>
		<small v-if="(!password.length > 0 && !confirmPassword.length > 0) || password !== confirmPassword" class="warn">
			{{ languageStrings.passwordsMustMatch }}
		</small>
		<div id="pw-strength-container">
			<p>
				{{ languageStrings.passwordStrength }}:
				<span v-if="newPassword?.length > 0" class="score-words" :class="strengthCheck">{{ strengthCheck }}</span>
			</p>
			<progress id="pw-strength" class="custom-bar" :class="strengthCheck" :value="pwStrength" max="100">
				{{ languageStrings.passwordStrength }}
			</progress>
		</div>
	</div>
</template>

<script>
export default {
	name: "PasswordForm",
	props: {
		serverRules: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			password: "",
			confirmPassword: "",
			pwStrength: 0,
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "PasswordForm",
			},
		};
	},
	watch: {
		password() {
			this.passwordStrengthCheck();
		},
		confirmPassword() {
			this.passwordStrengthCheck();
		},
	},
	created() {},
	mounted() {},
	methods: {
		passwordStrengthCheck() {
			this.pwStrength = this.scorePassword(this.password);

			if (this.pwStrength <= 33) this.strengthCheck = "weak";
			if (this.pwStrength > 33 && this.pwStrength < 66) this.strengthCheck = "medium";
			if (this.pwStrength >= 66) this.strengthCheck = "strong";

			if (this.password === this.confirmPassword && this.password.length >= this.serverRules.passwordRules?.minimumLength) {
				this.matchedCharClasses = this.characterClassCheck(this.password, this.serverRules.passwordRules?.minimumNumberOfCharacterClasses);
				this.validNumberCharClasses = this.matchedCharClasses.characterClassesValid;
			}
		},
		submitToParent() {
			if ((!this.password.length > 0 && !this.confirmPassword.length > 0) || this.password !== this.confirmPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.passwordDoNotMatch;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			// if (this.password.length >= this.serverRules.passwordRules?.minimumLength) {
			// 	this.status.ok = false;
			// 	this.status.message = this.languageErrorStrings.passwordDoNotMatch;
			// 	this.eventBus.emit("updateStatus", this.status);
			// 	return false;
			// }

			this.newAccountProperty.propertyName = "password";
			this.newAccountProperty.propertyValue = this.password;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
}

small {
	font-size: 1rem;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}
</style>
